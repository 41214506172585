.main-section {
  display: flex;
  width: 100%;
  height: 100vh;
}

.section-left {
  background-color: #ffffff;
  width: 22%;
}

.section-right {
  background-color: #f7f7f2;
  width: 78%;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 3%;
}

.path-section {
  height: 10%;
}

.child-section {
  height: 88%;
}

.caterogyPageScroll {
  height: calc(100vh - 335px);
}

.categoryImageDiv {
  height: 50px !important;
  width: 50px !important;
  background-color: #F1F9F3 !important;
}

.categoryImage {
  object-fit: cover !important;
}

.homepageModifierPadding {
  padding: 2rem 2rem 2rem 6rem;
}

.homepageModifierInput {
  height: 250px;
}

.homepageModifierCategoryScroll {
  height: calc(100vh - 270px);
}

.homepageModifierCategoryInventoryScroll {
  height: calc(100vh - 320px);
}

.corner-position {
  /* position: absolute; */
  position: relative;
  top: -15px;
  right: -80px;
  width: 15px;
  height: 15px;
}

.button-green {
  background: #12352F !important;
  color: #FEBF22;
}

.homepagemodifieraddedproductslable {
  color: #3D3D3D;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.homepagemodifieraddedproductsinput {
  display: flex;
  width: 590px;
  height: 48px;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #E0E0E0;
  align-items: center;
  gap: 360px;
}

.homepagemodifierselectedproducts {
  width: 590px;
  height: max-content;
  border-radius: 6px;
  border: 1px solid #E0E0E0;
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.homepagemodifiernativitybannerinput {
  width: 590px;
  height: 262px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #E0E0E0;
}

.homepagemodifileradvertisementdropdown {
  width: 75%;
  height: 48px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #E0E0E0;
}

.empty-product-list{
  color: #808080;
}