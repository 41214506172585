.societyinternalheadinginfo {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #3d3d3d;
}

.societyinternalfloor {
  padding: 28px;
  gap: 28px;

  height: 450px;

  background: #ffffff;
  border-radius: 6px;
}

.societyinternalp {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3d3d3d;
}

.societyinternalp1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3d3d3d;
  /* margin: 0px; */
  /* padding: 0px; */
}

.societyinternalfloorbuttonactive {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 9px 10px 10px;
  background-color: white;
  width: 40px;
  height: 40px;

  border: 1px solid #1c1b1f;
  border-radius: 6px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */


  color: #A3A3A3;
  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.societyinternalfloorbutton {
  box-sizing: border-box;

  /* Auto layout */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */


  color: #A3A3A3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 9px 10px 10px;
  gap: 296px;
  background-color: white;

  width: 40px;
  height: 40px;

  border: 1px solid #e0e0e0;
  border-radius: 6px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.societyinternalfloordeco {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  width: 650px;
}