
.boox1{
    height: 560px;
    width: 499px;
    background-color: #F7F7F2;
    border-radius: 12px;
}
.inbox{
    height: 84px;
    width: 379px;
    background-color:#FFEDC2;
    border-radius: 12px;
    margin-left: 57px;
}
.lablesall {
    justify-content: space-between;
    display: flex;
    padding: 0%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    color: #666666;
  }
  .txtcnt{
    text-align: center;
    color: #12352F;
    font-family: "Inter";
    font-style: normal;
  }
  .tempheit{
    height: 28px;
  }
  
  .lnhgt{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #3D3D3D;
  }
  .fntbig{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #3D3D3D;
  }
  .alinend{
    text-align: right;
  }
  .mrgtop{
    margin-top: 16px;
  }
  .mblside{
    margin-left: 42px;
    margin-top: 11px;
  }
  .hibfntb{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
  }
  .hibfntcl{
    justify-content: space-between;
    display: flex;
    padding: 0%;

    margin-top: 30px;
  }
  .spaccesl{
    height: 120px;
  }
  .imgpoab{
    position: absolute;
  }
 .togbtn{
    margin-right: 13px;
 }

  /* div input{
    display: none;
  }

  div label{
    position: relative;
    display: block;
    width: 40px;
    height: 19px;
    background-color: #5b5e5a;
    border-radius: 20px;
    cursor: pointer;
  }

 div label:before{ 
    position: absolute;
    top: 50%;
    left: 4px;
    transform: translatey(-50%);
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 50px;
 }


 div input:checked + label{
    background: #9CEA85;
 }

 div input:checked + label:before{
    left: 22px;
 }
 */
