.main-section {
  display: flex;
  width: 100%;
  height: 100vh;
}

.section-left {
  background-color: #ffffff;
  width: 22%;
}

.section-right {
  background-color: #f7f7f2;
  width: 78%;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 3%;
}

.path-section {
  height: 10%;
}

.child-section {
  height: 88%;
}

.caterogyPageScroll {
  height: calc(100vh - 335px);
}

.categoryImageDiv {
  height: 50px !important;
  width: 50px !important;
  background-color: #f1f9f3 !important;
}

.categoryImage {
  object-fit: cover !important;
}

.model-header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
  text-transform: uppercase;
  color: #3d3d3d;
}

.model-header-heading {
  padding-top: 8px;
  padding-bottom: 8px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  text-transform: uppercase;

  color: #3D3D3D;
}

.model-header-info {
  padding-top: 8px;
  padding-bottom: 8px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */


  color: #3D3D3D !important;
}

.inventory-overview {
  padding-bottom: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-transform: uppercase;

  color: #3d3d3d;
}

td:first-child {
  border-left-style: solid;
  border-top-left-radius: 10px; 
  border-bottom-left-radius: 10px;
}
td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px; 
  border-top-right-radius: 10px; 
}