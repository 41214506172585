.main-section {
    display: flex;
    width: 100%;
    height: 100vh;
}

.section-left {
    background-color: #ffffff;
    width: 22%;
}

.section-right {
    background-color: #f7f7f2;
    width: 78%;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 3%;
}

.path-section {
    height: 10%;
}

.child-section {
    height: 88%;
}

.caterogyPageScroll {
    height: calc(100vh - 335px);
}

.categoryImageDiv {
    height: 50px !important;
    width: 50px !important;
    object-fit: "cover";
    background-color: #f1f9f3 !important;
}

.categoryImage {
    height: 40px !important;
    width: 40px !important;
    object-fit: cover !important;
}

#vehicle1 {
    width: 23px;
    height: 23px;
    background: #f0efef;
    border-radius: 3px;
}

.selected {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 8px;

    width: 75px;
    height: 30px;

    border: 1px solid #12352f;
    border-radius: 6px;
}

.user-photo {
    width: 51px;
    height: 51px;
    background: rgba(255, 237, 194, 0.56);
    border-radius: 4px;
    text-align: center;
}

.user-initial {
    font-weight: 700;
    font-size: 14px;
    color: #3d3d3d;
    margin: 0px;
}

.dropdownoptionUserListing {
    position: absolute;
    width: 150px !important;
    background: #ffffff;
    margin: 1px;
    padding: 8px;
    z-index: 1;
    /* top: 250px;
        right: 20px; */
    box-shadow: -1px 3px 10px rgba(83, 82, 82, 0.13);
}
.userListingPageScroll{
    height: calc(100vh - 565px);
}