* {
  margin: 0px;
  padding: 0px;
}
.main-div {
  width: 100%;
  height: 100vh;
  background: #12352f;
}
.first-box {
  background: #ffffff;
  border-radius: 25px;
  width: 100%;
  min-height: 570px;
}
.first-label {
  width: 212px;
  height: 29px;
  /* font-family: "Inter"; */
  /* font-style: normal; */
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #12352f;
}
.second-label {
  /* font-family: "Inter"; */
  /* font-style: normal; */
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3d3d3d;
}

.forget-text {
  /* width: 91px; */
  /* height: 15px; */
  /* font-family: "Inter"; */
  /* font-style: normal; */
  font-weight: 1000 !important;
  font-size: 15px  !important;
  line-height: 15px;
  text-decoration-line: underline;
  color: #7d7d7d;
}

.form-control {
  font-weight: "1000" !important;
  color: "#7D7D7D" !important;
}

