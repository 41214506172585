* {
  margin: 0px;
  padding: 0px;
}
.main-div {
  width: 100%;
  height: 100vh;
  background: #12352f;
}
.first-box {
  background: #ffffff;
  border-radius: 25px;
  width: 100%;
  min-height: 570px;
}
.first-text {
  width: 116px;
  height: 29px;
  /* font-family: "Inter"; */
  /* font-style: normal; */
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #12352f;
  margin-top: 20px;
}
.second-text {
  /* font-family: "Inter"; */
  /* font-style: normal; */
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3d3d3d;
}
.third-text {
  width: 141px;
  height: 15px;
  /* font-family: "Inter"; */
  /* font-style: normal; */
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #7d7d7d;
}
.fourth-text {
  width: 141px !important;
  height: 15px !important;
  /* font-family: "Inter"; */
  /* font-style: normal; */
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #12352f !important;
}
.form-control {
  font-weight: "1000" !important;
  color: "#7D7D7D" !important;
}
