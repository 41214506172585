.inbox1 {
  height: 482.63px;
  width: 492px;
  background-color: #f1f9f3;
}

.container {
  /* height:692px;
    width: 1038px; */
  background-color: #ffff;
}

#btninstock {
  background-color: #afe27c;
  color: black;
  border: none;
}

.img {
  margin-top: 80px;
  margin-left: 20px;
}

.border-properties {
  border-radius: 5px;
  text-align: center;
  background-color: #afe27c;
  color: black;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 135%;
  padding: 8px 16px;
  gap: 10px;
}

.border2 {
  border-radius: 5px;
  text-align: center;
  border-color: black;
  color: black;
  border: 2px solid #8f8f8f;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 135%;
  padding: 8px 16px;
  gap: 8px;
}

.rowhight {
  height: 35px;
}

.textbox {
  height: 238px;
  width: 400px;
  font-size: smaller;
}

.lables {
  justify-content: space-between;
  display: flex;
  padding: 0%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  color: #666666;
}

.divWidth {
  width: 400px;
}

.infobox {
  height: 60px;
  width: 399px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #a3a3a3;
  padding: 0%;
}

.fontsize {
  font-size: smaller;
}

.hight1 {
  height: 15px;
}

.hight2 {
  height: 50px;
}

.hight3 {
  height: 50px;
}

.linehight {
  line-height: 14px;
}

.padding {
  padding: 0%;
}

.colour {
  color: #12352f;
}

.imgcolor {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 112.52%;
}

.resize {
  margin-left: -25px;
}

.resizer {
  margin-left: 60px;
}
.products-actionbutton {
  box-sizing: border-box;

  width: 88px;
  /* height: 37px; */

  background: #12352f;
  border: 0.8px solid #12352f;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  color: #febf22;
}

.low-stock {
  background-color: orange;
}

.on-stock {
  background-color: #afe27c;
}

.no-stock {
  background-color: red;
}

