.btn-unupload-green-color{
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6.18px;
    width: 100px;
    height: 31.55px;
    left: 1220px;
    top: 395px;
    color: #FEBF22;
    background: #12352F;
    border-radius: 4.63636px;
}
.btn-upload-green-color{
flex-direction: row;
justify-content: center;
align-items: center;
gap: 6.18px;

width: 100px;
height: 31.55px;
left: 1220px;
top: 303px;
color: #3D3D3D;
background: #B3B3B3;
border-radius: 4.63636px;
}
.bulkuploadbutton-cancel{
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8.36364px 8.36364px 8.36364px 11.1515px;
gap: 5.58px;

width: 92px;
height: 33.84px;

background: #FFFFFF;
box-shadow: -1.27545px 3.81939px 12.7058px rgba(0, 0, 0, 0.25);
border-radius: 4.18182px;

/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
}