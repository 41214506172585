.custominventory500 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    font-feature-settings: 'ss01' on;
    color: #3D3D3D;
}

.custominventory400 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    font-feature-settings: 'ss01' on;
    color: #3D3D3D;
}

.customeinventoryData {
    width: 150px;
    height: 47px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 10px;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    /* Inside auto layout */
    /* flex: none; */
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.addInventoryButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 12px;

    width: 168px;
    height: 48px;

    background: #12352F;
    border-radius: 6px;


    flex: none;
    order: 0;
    flex-grow: 0;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    font-feature-settings: 'ss01' on;

    color: #FEBF22;
}


/* custominventoryhistory */


.custominventoryhistoryheading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    text-transform: uppercase;
    font-feature-settings: 'ss01' on;

    color: #3D3D3D;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.custominventoryhistorydata {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */


    color: #3D3D3D;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.itemSelectingdropdown {
    box-sizing: border-box;

    /* Auto layout */
    /* position: absolute; */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 14px;
    gap: 16px;

    height: 50px;
    width: 250px;
    background-color: white;
    border: 1px solid #E7E7E7;
    border-radius: 5px;
    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;

    color: #3D3D3D;

}

.itemSelectingdropdownimg {
    width: 36px;
    height: 36px;
    left: 14px;
    top: 7px;

    background: #F1F9F3;
    border-radius: 4px;
}

.dropdowns{
    position: absolute;
}


.itemSelectingdropdow {
    box-sizing: border-box;

    /* Auto layout */
    /* position: absolute; */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 14px;
    gap: 16px;
    background-color: white;
    border: 1px solid #E7E7E7;
    border-radius: 5px;
    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;

    color: #3D3D3D;

}