.containerbg {
  height: calc(100vh - 120px);
}

.rowhight {
  height: 35px;
}

#row {
  height: 65px;
}

.center {
  text-align: center;
}

.Uploaded {
  height: "100px";
}

.Uploadimg {
  height: 212px;
  width: 213px;
}

#btnRegister {
  background: #12352f;
  color: yellow;
  height: 48px;
  width: 212px;
}

.unique {
  width: 129px;
  height: 71px;
}

.code {
  width: 129px;
  height: 48px;
}

.Textbar {
  width: 448px;
  height: 48px;
}

.smtext {
  height: 48px;
  width: 212px;
}

.box {
  height: 154px;
  width: 448px;
}

.margin {
  margin-left: 10%;
}

.margintext {
  margin-left: 19px;
}

.adhar-box {
  width: 448px;
  height: 154px;
  /* border: 1px solid #e0e0e0; */
  border-radius: 6px;
}

.profile-box {
  width: 200px;
  height: 200px;
  /* border: 1px solid #e0e0e0; */
  border-radius: 6px;
}

.homepageModifierInput {
  height: 500px;
}
.eye-icone {
  background-image: url("") !important;
}
.btn-franchise-green-color{
  flex-direction: row;
  justify-content: center;
  align-items: center;
  left: 1220px;
  top: 395px;
  color: #FEBF22;
  background: #12352F;
  border-radius: 4.63636px;
  padding: 7px 40px;
}

.remove-spinner::-webkit-inner-spin-button,
.remove-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.remove-spinner {
  -moz-appearance: textfield; /* Firefox */
}

/* MultiSelectDropdown.css */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  /* Set a fixed width for the button */
  width: 445px; /* You can adjust this value as needed */
  padding: 8px 16px;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: white;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Show ellipsis (...) for overflow */
  border-radius: 5px;
}

.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 445px; /* Set the fixed width */
  border-radius: 5px;
}

.dropdown-options label {
  display: block;
  padding: 8px 16px;
}

.apply-button {
  width: 100%;
  padding: 8px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.apply-button:hover {
  background-color: #0056b3;
}